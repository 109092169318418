import { createBrowserRouter } from "react-router-dom";
import ComingSoon from "../Pages/ComingSoon";
import ErrorPage from "../Pages/Error";

const router = createBrowserRouter([
    {
        path: "/",
        element: <ComingSoon />,
        errorElement: <ErrorPage />,
    }
]);

export default router