import { extendTheme } from "@chakra-ui/react"

/*
************************************
** Color Palette Generator
** from: https://themera.vercel.app/
************************************
*/

const palette = extendTheme({
    colors: {
        moon_mist: {
            "default": "#DEDEC9",
            "50": "#F5F5EF",
            "100": "#E4E4D3",
            "200": "#D3D3B6",
            "300": "#C1C19A",
            "400": "#B0B07D",
            "500": "#9E9E61",
            "600": "#7F7F4D",
            "700": "#5F5F3A",
            "800": "#3F3F27",
            "900": "#202013"
        },
        satin_linen: {
            "default": "#EAEAD9",
            "50": "#F6F6EF",
            "100": "#E6E6D1",
            "200": "#D5D5B3",
            "300": "#C5C596",
            "400": "#B5B578",
            "500": "#A4A45B",
            "600": "#838349",
            "700": "#636336",
            "800": "#424224",
            "900": "#212112"
        },
        muddy_waters: {
            "default": "#B87E58",
            "50": "#F7F1ED",
            "100": "#EAD8CD",
            "200": "#DCBFAD",
            "300": "#CEA78C",
            "400": "#C18E6C",
            "500": "#B3754C",
            "600": "#8F5D3D",
            "700": "#6B462E",
            "800": "#482F1E",
            "900": "#24170F"
        },
        stromboli: {
            "default": "#2E4942",
            "50": "#EFF5F4",
            "100": "#D3E3DF",
            "200": "#B7D2CB",
            "300": "#9BC0B6",
            "400": "#7FAEA2",
            "500": "#639C8D",
            "600": "#4F7D71",
            "700": "#3B5E55",
            "800": "#273F39",
            "900": "#141F1C"
        }
    },
})

export default palette;
