import companyInfoInterface from './Interfaces/companyInfo';

const companyInfo: companyInfoInterface = {
    siteMeta: {
        title: 'Aim',
        description: 'Website description!'
    },
    name: "Aim",
    address: {
        street: 'Street',
        number: 'number',
        city: 'city',
        postcode: 'postcode',
        country: 'Malta'
    },
    telephone: '+356 9962 7154',
    email: 'info@aim.mt'
}

export default companyInfo;