import { Box, chakra, Container, Icon, Stack, Text, VisuallyHidden } from "@chakra-ui/react";
import { Logo } from "../Logo";
import companyInfo from '../branding/companyInfo';
import { ReactNode } from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";

const SocialButton = ({
    children,
    label,
    href,
    brand
}: {
    children: ReactNode;
    label: string;
    href: string;
    brand: string;
}) => {
    return (
        <chakra.button
            bg={'transparent'}
            borderRadius={'full'}
            w={12}
            h={12}
            cursor={'pointer'}
            as={'a'}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'color 0.5s ease-in-out, background 0.5s ease-in-out'}
            _hover={{
                color: brand,
                bg: 'blackAlpha.100',
            }}>
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    );
};

export default function ComingSoon() {

    return (
        <>
            <Box bg="primary.900" color="secondary.100" p={'32px'}
                height={'100vh'}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Logo h="16vw" pointerEvents="none" />
                <Text
                    textAlign={'center'}
                    fontWeight={'bold'}
                    fontSize={{ base: '24px', md: '3vw' }}
                    color={'stromboli.default'}
                    p={'32px 0'}
                >
                    Coming Soon
                </Text>
                {/* <Text
                    textAlign={'center'}
                    fontWeight={'bold'}
                    fontSize={{ base: '18px', md: '2.5vw' }}
                    color={'stromboli.700'}
                    padding={'0 0 32px 0'}
                >
                    We’re working hard<br />
                    to bring the Aim experience to you
                </Text> */}
                <Text
                    textAlign={'center'}
                    fontSize={{ base: '16px' }}
                    color={'stromboli.700'}
                >
                    In the meantime,<br />
                    If you’d like to get in touch,<br />
                    you can contact us by email or phone<br />
                    <Container
                        display={'flex'}
                        flexDirection={{ base: 'column', md: 'row' }}
                        justifyContent={'center'}
                        alignItems={'center'}
                        m={'16px 0 0 0'}
                        as={'span'}
                    >
                        <Box w={'160px'} p={'0 0 16px 0'} as={'span'}>
                            <b>Email</b><br />
                            <a href={"mailto:" + companyInfo.email} target={'_blank'} rel="noreferrer">{companyInfo.email}</a>
                        </Box>
                        <Box w={'160px'} p={'0 0 16px 0'} as={'span'}>
                            <b>Phone</b><br />
                            {companyInfo.telephone}
                        </Box>
                    </Container>
                    <Stack direction={'row'} spacing={2} justifyContent={'center'} as={'span'}>
                        <SocialButton
                            label={'Instagram'}
                            href={'https://instagram.com/aimmalta?igshid=YmMyMTA2M2Y='}
                            brand={'#dd2a7b'}
                            >
                            <Icon as={FaInstagram} w={16} h={8} /></SocialButton>
                        <SocialButton
                            label={'Facebook'}
                            href={'https://www.facebook.com/aimmalta'}
                            brand={'#4267B2'}
                        >
                            <Icon as={FaFacebook} w={16} h={8} />
                        </SocialButton>
                    </Stack>
                </Text>
            </Box>
        </>
    )
}