import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  ChakraProvider,
  Icon,
  Text
} from "@chakra-ui/react"

import {
  RouterProvider,
} from "react-router-dom";


import HeaderNav from "./Components/HeaderNav/HeaderNav";
import Footer from "./Components/Footer/Footer";

import "./App.scss";

import router from "./branding/router";
import companyInfo from "./branding/companyInfo";
import { Helmet, HelmetProvider } from "react-helmet-async";
import palette from "./branding/palette";
import { FaChevronUp } from "react-icons/fa";


export const App = () => {

  const [scrollPosition, setScrollPosition] = useState(0);
  const [showGoTop, setShowGoTop] = useState("goTopHidden");
  const refScrollUp: any = useRef();


  const handleVisibleButton = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);

    if (scrollPosition > 50) {
      return setShowGoTop("goTop");
    } else if (scrollPosition < 50) {
      return setShowGoTop("goTopHidden");
    }
  };

  const handleScrollUp = () => {
    refScrollUp.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleVisibleButton);
  });

  const GoTop = (props: any) => {
    return (
      <Text className={props.showGoTop} onClick={props.scrollUp} bg={'secondary.100'} 
      transition={'background 0.3s ease-in-out'}
        _hover={{
          background: "secondary.200",
        }}
      >
        {props.icon}
      </Text>
    );
  };

  return (
    <ChakraProvider theme={palette}>
      <div ref={refScrollUp}> </div>
      <HelmetProvider>
        <Helmet>
          <title>{companyInfo.siteMeta.title}</title>
          <meta name="description" content={companyInfo.siteMeta.description} />
        </Helmet>
      </HelmetProvider>
      {/* <HeaderNav /> */}
      <Box>
        <React.StrictMode>
          <RouterProvider router={router} />
        </React.StrictMode>
      </Box>
      {/* <Footer /> */}
      {/* <GoTop showGoTop={showGoTop} scrollUp={handleScrollUp}
        icon={<Icon as={FaChevronUp} w={6} h={6} fill={'primary.700'} />} /> */}
    </ChakraProvider>
  )
}
